import React, { Component } from 'react';
import swal from 'sweetalert';
import Title from '../GenericComponents/Title';
import {get_ApiManager, post_ApiManager, view_ApiManager } from '../ApiManager/ApiManager';
import moment from 'moment';
import TaskModal from './TaskModal';
import Loader from '../Loader/Loader';
import DOMPurify from "dompurify";
import { getAllTasks, getUnclaimTask } from '../Services/apiServices';
import ReactTooltip from "react-tooltip";

class ProcessingTasksCards extends Component {
    constructor(props) {
        super(props);
        var user = JSON.parse(localStorage.getItem('user'));
        var permissions = JSON.parse(localStorage.getItem('permissions'));
        this.state = {
            user: user,
            isManager: Number(permissions?.['manage_tasks']) === 1,
            // isManager: 0,
            highPriorityTasks: [],
            highPerPage:5,
            perPage:5,
            currentPage: 1,
            highCurentPage: 1,
            lastPage:1,
            highLastPage:1,
            standardTasks: [],
            loading: true,
            selectedTask: null,
            showTaskModal: false,
            allPermissions: permissions,
            usersList: [],
            processingActions: new Set(),
            loadingState:false,
        };
    }

    componentDidMount() {
        this.fetchTasks();
        if (this.state.isManager) {
            this.fetchAllUsers();
        }
    }

    claimTask = async (taskId) => {
        if (this.state.processingActions.has(`claim-${taskId}`)) return;
        this.setState(prevState => ({
            processingActions: new Set(prevState.processingActions).add(`claim-${taskId}`)
        }));
        const data = { 
            claimed_by: this.state.user.id,
            assigned_to: this.state.user.id
        };
        let url = `claimTask/${taskId}`;
        try {
            post_ApiManager(data, url, (res) => {
                if (res.data.status) {
                    this.fetchTasks();
                    swal("Success", "Task claimed successfully", "success");
                } else {
                    swal("Error", "Failed to claim task", "error");
                }
            }, (err) => {
                console.log(err);
                swal("Error", "Failed to claim task", "error");
            });
        } 
        finally {
        this.setState(prevState => {
        const newProcessingActions = new Set(prevState.processingActions);
        newProcessingActions.delete(`claim-${taskId}`);
        return { processingActions: newProcessingActions };
    });
        }
    }

    fetchTasks = async () => {
        let { currentPage, highCurrentPage, perPage, highPerPage} = this.state;
        let result = await getAllTasks({
            page_high: highCurrentPage, 
            page_standard: currentPage, 
            perPage: perPage, 
            highPerPage: highPerPage});
            this.setState({loading:true})
        console.log('all-tasks', result.data.data)
        if (result.error) {
            swal({ text: result.error, icon: "warning", dangerMode: true });
        } else {
            this.setState({
                loading:false,
                highPriorityTasks: result.data.data['high_priority']['data'],
                highPerPage: result.data.data['high_priority']['per_page'],
                highLastPage: result.data.data['high_priority']['last_page'],
                standardTasks: result.data.data['standard_priority']['data'],
                perPage: result.data.data['standard_priority']['per_page'],
                lastPage: result.data.data['standard_priority']['last_page'],
            })
        }
    };
    
    
    checkIfOverdue = (due_date) => {
        if (!due_date) return false;
        // Reset time to start of day for accurate comparison
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        const taskDueDate = new Date(due_date);
        taskDueDate.setHours(0, 0, 0, 0);
        
        return taskDueDate < today;
    };

    renderDueDate = (task) => {
        if (!task.due_date) return '---';
        try {
            const formattedDate = new Date(task.due_date).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            });

            const isOverdue = this.checkIfOverdue(task.due_date);
            
            const style = {
                display: 'inline-block',
                padding: '6px 12px',
                borderRadius: '4px',
                fontWeight: isOverdue ? 'bold' : 'normal',
                color: isOverdue ? '#fff' : '#000',
                backgroundColor: isOverdue ? '#dc3545' : '#e2e3e5', // Red for overdue, light gray for normal
                border: isOverdue ? '1px solid #dc3545' : '1px solid #d6d8db'
            };

            return (
                <span style={style}>
                    {formattedDate}
                </span>
            );
        } catch (error) {
            console.error('Error formatting date:', error);
            return 'Invalid date';
        }
    };

    handleModalOpen = (type, task = null) => {
        this.setState({
            showTaskModal: true,
            modalType: type,
            selectedTask: task
        });
    };

    handleModalClose = () => {
        this.setState({
            showTaskModal: false,
            modalType: null,
            selectedTask: null
        });
    };

    assignTask = (taskData) => {
        if (!this.state.isManager) return;
        const data = {
            ...taskData,
            created_by: this.state.user.id,
            claimed_by: null // Initialize as unclaimed
        };
        console.log('assign',data)
        let url = 'generateTask';
        post_ApiManager(data, url, (res) => {
            console.log('generate task', res)
            if (res.data.status) {
                this.fetchTasks();
                swal("Success", "Task assigned successfully", "success");
                this.handleModalClose();
            } else {
                swal("Error", "Failed to assign task", "error");
            }
        }, (err) => {
            console.log(err);
            swal("Error", "Failed to assign task", "error");
        });
    };

    reassignTask = (taskData) => {
        if (!this.state.isManager) return;

        const { taskId } = taskData;
        const data = {
            ...taskData,
            claimed_by: taskData.assigned_to // Update claimed_by when reassigning
        };
        let url = `reassignTask/${taskId}`;
        post_ApiManager(data, url, (res) => {
            console.log('reassign', res)
            if (res.data.status) {
                this.fetchTasks();
                swal("Success", "Task assigned successfully", "success");
                this.handleModalClose();
            } else {
                swal("Error", "Failed to assign task", "error");
            }
        }, (err) => {
            console.log(err);
            swal("Error", "Failed to assign task", "error");
        });
    };

    fetchAllUsers = async () => {
        let url = 'getAllUsers';
        view_ApiManager(url, 0, 0, (res) => {
            console.log('Get all Users', res);
            this.setState({ usersList: res.data.data || [] });
        }, (err) => {
            console.log(err);
        });
    }

    handleStatusChange = async (taskId, status) => {
        if (this.state.processingActions.has(`status-${taskId}`)) return;

        this.setState(prevState => ({
            processingActions: new Set(prevState.processingActions).add(`status-${taskId}`)
        }));

        const data = { status: status };
        let url = `updateTaskStatus/${taskId}/${status}`;

        try {
            post_ApiManager(data, url, (res) => {
                console.log('update', res)
                        if (res.data.status) {
                            this.fetchTasks();
                            swal("Success", "Task status updated successfully", "success");
                        } else {
                            swal("Error", "Failed to update task status", "error");
                        }
                    }, (err) => {
                        console.log(err);
                        swal("Error", "Failed to update task status", "error");
                    });
                } 
                finally {
                this.setState(prevState => {
                const newProcessingActions = new Set(prevState.processingActions);
                newProcessingActions.delete(`status-${taskId}`);
                return { processingActions: newProcessingActions };
            });
        }
    }

    deleteTask =  (taskId) => {
        if (!this.state.isManager || this.state.processingActions.has(`delete-${taskId}`)) return;

        this.setState(prevState => ({
            processingActions: new Set(prevState.processingActions).add(`delete-${taskId}`)
        }));

        const data = { task_id: taskId };
        let url = `deleteTask/${taskId}`;

        try {
            post_ApiManager(data, url, (res) => {
                        if (res.data.status) {
                            this.fetchTasks();
                            swal("Success", "Task deleted successfully", "success");
                        } else {
                            swal("Error", "Failed to delete task", "error");
                        }
                    }, (err) => {
                        console.log(err);
                        swal("Error", "Failed to delete task", "error");
                    });
            } 
            finally {
            this.setState(prevState => {
                const newProcessingActions = new Set(prevState.processingActions);
                newProcessingActions.delete(`delete-${taskId}`);
                return { processingActions: newProcessingActions };
            });
        }
    }
    unclaimTask =  async(taskid) => {
         let url = `getUnclaimTask/${taskid}}`;
            get_ApiManager('', url,(res) => {
                console.log('unclaimed',res)
                if(res.data.error) swal(" ", res.data.error, "error")
                else {
                    swal("Success!", res.data.message, "success");
                    this.fetchTasks();
                }
            },(err) => {
                console.log(err);
            });  
    }
    
    renderTaskCard = (task) => {
        const sanitizedContent = DOMPurify.sanitize(`<b>Task:</b> ${task.task}`);
        const { isManager, user, processingActions } = this.state;
        const isTaskClaimed = task.claimed_by === user.id || task.assigned_to === user.id;
        const isClaimedByOther = task.assigned_to !== null && task.assigned_to !== user.id;
        let totalReassigns = task.reassigntask && Array.isArray(task.reassigntask) ? task.reassigntask.length : 0;
        let totalAttempts = task.attempts && Array.isArray(task.attempts) ? task.attempts.length : 0;
        
        const statusColors = {
            pending: 'bg-yellow-500',
            in_progress: 'bg-blue-500',
            completed: 'bg-green-500'
        };

        return (
            <div className="tailwind-container flex flex-wrap">
                <div className="w-full md:w-1/3 lg:w-1/4 p-1 mb-2">
                <div className={`bg-beige rounded-lg shadow-md h-full flex flex-col border-2 ${
                            Number(task.note_type) === 1
                                ? 'border-green-400'
                                :  Number(task.note_type) === 2 
                                ? 'border-blue-400'
                                : 'border-gray-400'
                        }`} >
                        {/* Header */}
                        <div className="p-3 border-b flex items-center justify-between">
                            <div className="flex items-center space-x-2">
                                <h3 className="text-base font-semibold text-gray-800 truncate">
                                    <a
                                        href={`/clients/view-client-detail/${task.clientid}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-blue-500 hover:underline"
                                    >
                                        {task.clientname}
                                    </a>
                                </h3>
                            </div>

                            <span className={`px-2 py-1 rounded-full text-xs font-medium text-white ${statusColors[task.status] || 'bg-gray-500'}`}>
                                {task.status?.replace('_', ' ').toUpperCase()}
                            </span>
                        </div>

                        {/* Content */}
                        <div className="p-3 flex-grow">
                            <div className="grid grid-cols-2 gap-2 text-sm mb-2">
                                <div>
                                    <span className="text-gray-500 text-xs">Application Type:</span>
                                    <p className="text-gray-800"> {Number(task.note_type) === 1 ? 'Migration' : 'Education'}</p>
                                </div>
                                <div className="flex justify-between">
                                    <span className="text-gray-500 text-xs"> </span>
                                    <p>
                                        {this.renderDueDate({
                                            due_date: task.due_date,
                                            isOverdue: task.isOverdue
                                        })}
                                    </p>
                                </div>
                            </div>
                            <div className="mb-2">
                                <span className="text-gray-500 text-xs">Application:</span>
                                <p className="text-gray-800 text-sm">
                                    {Number(task.note_type) === 1 ? (
                                        <a
                                            href={`/applications/view-migration-application-detail/${task.migration_id}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-500 hover:underline"
                                        >
                                            <div 
                                            className="truncate-text"
                                            data-tip= {task.migration_course_name  || "-"}
                                            data-for={`task-tooltip-${task.migration_id}`}
                                            dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(task.migration_course_name )
                                            }}
                                        />
                                        <ReactTooltip id={`task-tooltip-${task.migration_id}`} place="top" effect="solid" />
                                        </a>
                                    ) : Number(task.note_type) === 2 ? (
                                        <a href={`/applications/view-education-application-detail/${task.education_id}`}
                                            target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline"
                                        >
                                         <div 
                                            className="truncate-text"
                                            data-tip= {task.education_course_name || "-"}
                                            data-for={`task-tooltip-${task.education_id}`}
                                            dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(task.education_course_name)
                                            }}
                                        />
                                        <ReactTooltip id={`task-tooltip-${task.education_id}`} place="top" effect="solid" />
                                           
                                        </a>
                                    ) :  '-'}
                                </p>
                            </div>

                            <div className="mb-2">
                            <span className="text-gray-500 text-xs">Task:</span>
                            <div 
                                className="truncate-text"
                                data-tip={task.task}
                                data-for={`task-tooltip-${task.id}`}
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(task.task)
                                }}
                            />
                            <ReactTooltip id={`task-tooltip-${task.id}`} place="top" effect="solid" >
                            <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
                            </ReactTooltip>
                        </div>

                        <div className='grid grid-cols-2 gap-2 text-sm mb-3'>
                            <div className="">
                                <span className="text-gray-500 text-xs">Reassigned No:</span>
                                <p className="text-gray-800 text-sm w-100" data-tip data-for={`reassignTooltip-${task.id}`}>{totalReassigns}</p>

                                <ReactTooltip id={`reassignTooltip-${task.id}`} place="center" effect="solid">
                                    <div className="">
                                    {task.reassigntask[0] ? (
                                        task.reassigntask.map((reassign, index) => (
                                        <div key={index}>{++index}. {reassign.user}</div>
                                        ))
                                    ) : (
                                        <div>No Reassignments</div>
                                    )}
                                    </div>
                                </ReactTooltip>
                            </div>

                            <div className="">
                            <span className="text-gray-500 text-xs flex justify-end">Reattempts:</span>
                            <p className="text-gray-800 text-sm w-100 flex justify-end" data-tip data-for={`reattemptsTooltip-${task.id}`}>{totalAttempts}</p>

                            <ReactTooltip id={`reattemptsTooltip-${task.id}`} place="center" effect="solid">
                                    <div className="">
                                    {task.attempts[0] ? (
                                        task.attempts.map((attempt, i) => (
                                        <div key={i}>{++i}. {attempt.user}</div>
                                        ))
                                    ) : (
                                        <div>No Reattempts</div>
                                    )}
                                    </div>
                            </ReactTooltip>
                            </div>
                        </div>

                            <div className="grid grid-cols-2 gap-2 text-sm mb-3">
                                <div>
                                    <span className="text-gray-500 text-xs">Created By:</span>
                                    <p className="text-gray-800 truncate">{task.created_by_user}</p>
                                </div>
                                <div className="flex justify-end">
                                    <div >
                                        <span className="text-gray-500 text-xs flex justify-end">Assigned To:</span>
                                        <p className="text-gray-800 truncate text-right">{task.assigned_to_user || '-'}</p>
                                    </div>
                                </div>
                            </div>

                            {/* Actions */}
                            <div className="space-y-2">
                                {(isTaskClaimed ? (
                                    <>
                                    <select 
                                        value={task.status} 
                                        onChange={(e) => this.handleStatusChange(task.id, e.target.value)}
                                        disabled={processingActions.has(`status-${task.id}`)}
                                        className={`w-full p-1.5 text-sm border rounded ${
                                            processingActions.has(`status-${task.id}`) 
                                                ? 'bg-gray-200 cursor-not-allowed' 
                                                : 'bg-gray-50 focus:ring-2 focus:ring-blue-500 focus:ring-red-500' 
                                        }`}
                                    >
                                        <option value="pending">Pending</option>
                                        <option value="in_progress">In Progress</option>
                                        <option value="completed">Completed</option>
                                    </select>

                                    <button className='w-full white rounded py-1 bg-red-500 hover bg-red-600'
                                    onClick={() => this.unclaimTask(task.id)}>UNCLAIM TASK</button>
                                    </>
                                ) : <>
                                    <button
                                        onClick={() => this.claimTask(task.id)}
                                        disabled={isClaimedByOther || processingActions.has(`claim-${task.id}`)}
                                        className={`w-full text-white text-sm py-1.5 px-3 rounded ${
                                            isClaimedByOther 
                                                ? 'bg-gray-400 cursor-not-allowed'
                                                : processingActions.has(`claim-${task.id}`)
                                                ? 'bg-green-300 cursor-not-allowed'
                                                : 'bg-green-500 hover:bg-green-600'
                                        }`}
                                    >
                                        {isClaimedByOther ? 'CLAIMED' : processingActions.has(`claim-${task.id}`) ? 'Processing...' : 'CLAIM TASK'}
                                    </button>
                                    </>
                            )}

                            {isManager ? (
                                <div className="flex gap-2">
                                    <button
                                        onClick={() => this.handleModalOpen('reassign', task)}
                                        disabled={processingActions.has(`reassign-${task.id}`)}
                                        className={`flex-1 text-white text-sm py-1.5 px-3 rounded ${
                                            processingActions.has(`reassign-${task.id}`)
                                                ? 'bg-yellow-300 cursor-not-allowed'
                                                : 'bg-yellow-500 hover:bg-yellow-600'
                                        }`}
                                    >
                                        Reassign
                                    </button>
                                    <button 
                                        onClick={() => this.deleteTask(task.id)}
                                        disabled={processingActions.has(`delete-${task.id}`)}
                                        className={`flex-1 text-white text-sm py-1.5 px-3 rounded ${
                                            processingActions.has(`delete-${task.id}`)
                                                ? 'bg-red-300 cursor-not-allowed'
                                                : 'bg-red-500 hover:bg-red-600'
                                        }`}
                                    >
                                        {processingActions.has(`delete-${task.id}`) ? 'Deleting...' : 'Delete'}
                                    </button>
                                    </div>
                                ):''}
                            </div>
                        </div>
                </div>
                </div>
            </div>
        );
    }

    renderTaskSection = (tasks, title, currentPage, perPage, lastPage, from) => {
        return (
            <div className="tailwind-container bg-white border rounded my-3">
                <div className="mb-6">
                    <h2 className="text-center py-3 mb-3 text-lg font-semibold bg-yellow-100 rounded">
                        {title}
                    </h2>
                    {/* <div className="flex flex-wrap -mx-3"> */}
                    <div className="flex flex-wrap -mx-3 justify-content-center px-2">
                        {tasks.map(task => this.renderTaskCard(task))}
                    </div>
                    {(lastPage > currentPage) && (
                    <div className='row px-4 mt-4'>
                         <button type='button' className='btn btn-appointment p-2'
                          onClick={(e) => this.handleShowMore(e,from)}
                          disabled={this.state.loadingState}
                          >{this.state.loadingState ?  '...': "Show More"}</button>
                    </div> )}
                </div>
            </div>
        );
    }

    handleShowMore = (e, from) => {
    e.preventDefault();
    console.log(from)
        this.setState({ loadingState: true }, () => {
            this.setState(
                (prevState) => ({
                    highPerPage: from === "high" ? prevState.highPerPage + prevState.highPerPage : prevState.highPerPage,
                    perPage: from !== "high" ? prevState.perPage + prevState.perPage : prevState.perPage,
                    loadingState: false
                }),
                () => {
                    this.fetchTasks();
                }
            );
        });
    };    

    render() {
        const { highPriorityTasks, standardTasks, isManager, showTaskModal, selectedTask, modalType } = this.state;    
        return (
            <div className="tailwind-container">
                <div className="min-h-screen bg-gray-50">
                    <Title 
                        title={isManager ? "All Tasks" : "My Tasks"}
                        btnCol="col-lg-8 text-center pt-lg-1" 
                        btn={true}
                        btnText="Completed Tasks" 
                        btnClass="float-right text-right white text-sm py-1.5 px-3 rounded bg-blue-500 hover bg-blue-600'
                                }"
                        btnUrl={`/processing-reports`}
                    />
                    
                    <div className="container mx-auto px-3 py-6">
                        <h2 className="text-center text-xl font-bold text-blue-600 mb-1">
                            {moment().format('MMMM Do YYYY')}
                        </h2>
                        {/* {isManager ? (
                        <div className="text-right">
                            <button
                                onClick={() => this.handleModalOpen('create')}
                                className={`text-right white text-sm py-1.5 px-3 rounded bg-yellow-500 hover bg-yellow-600'
                                }`}
                            >
                                Create Task
                            </button></div>
                        ):''} */}
        
                        {this.renderTaskSection(highPriorityTasks, 'High Priority Tasks', this.state.highCurentPage, this.state.highPerPage, this.state.highLastPage,'high')}
                        {this.renderTaskSection(standardTasks, 'Standard Priority Tasks', this.state.currentPage, this.state.perPage, this.state.lastPage,'standard')}
        
                        {isManager ? (
                            <TaskModal
                                show={showTaskModal}
                                onClose={this.handleModalClose}
                                onSubmit={modalType === 'create' ? this.assignTask : this.reassignTask}
                                currentUserId={this.state.user.id}
                                modalType={modalType}
                                selectedTask={selectedTask}
                                usersList={this.state.usersList}
                            />
                        ):''}
                    </div>
                </div>
            </div>
        );
    }
}

export default ProcessingTasksCards;