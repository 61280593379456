import React, { useState, useEffect } from "react";
import Title from '../GenericComponents/Title';
import swal from "sweetalert";
import DOMPurify from "dompurify";
import { Sorting } from "../GenericComponents/SortTables";
import moment from "moment";
import { post_ApiManager, search_ApiManager, view_ApiManager} from "../ApiManager/ApiManager";
import Paginations from "../GenericComponents/Pagination";
import MultipleSearchs from "../GenericComponents/MultipleSearchs";
import { getCompletedTasks } from "../Services/apiServices";
import { category, priorities } from "../../utilis/constants";

const ProcessingReports = () => {
    const [allHistory, setAllHistory] = useState([]);
    const [order, setOrder] = useState("ASC");
    const [perItem, setPerItem] = useState('');
    const [totalItem, setTotalItem] = useState('');
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState('');
    const [lastPage, setLastPage] = useState('');
    const [allUsers, setAllUsers] = useState([]);
    const [createdBy, setCreatedBy] = useState([]);
    const [assignedTo, setAssignedTo] = useState([]);
    const [priority, setPriority] = useState('');
    const [appType, setAppType] = useState('');
    const isManager = Number(JSON.parse(localStorage.getItem('permissions'))?.['manage_tasks']) === 1;

    const [headers] = useState([
        { title: "Sr No", dataKey: "index", align: "text-center", key: 1, checked: true, canChange: true },
        { title: "Tasks", dataKey: "task", align: "text-center", key: 2, checked: true, canChange: true },
        { title: "Client", dataKey: "client_name", align: "text-center", key: 3, checked: true, canChange: true },
        { title: "Created By", dataKey: "created_by_user", align: "text-center", key: 4, checked: true, canChange: true },
        { title: "Assigned To", dataKey: "assigned_to_user", align: "text-center", key: 5, checked: true, canChange: true },
        { title: "App Type", dataKey: "note_type", align: "text-center", key: 6, checked: true, canChange: true },
        { title: "Application", dataKey: "created_at", align: "text-center", key: 7, checked: true, canChange: true },
        { title: "Priority", dataKey: "priority", align: "text-center", key: 8, checked: true, canChange: true },
        { title: "Status", dataKey: "status", align: "text-center", key: 9, checked: true, canChange: true },
        { title: "Due Date", dataKey: "due_date", align: "text-center", key: 10, checked: true, canChange: true },
        { title: "Completed Date", dataKey: "complete_date", align: "text-center", key: 11, checked: true, canChange: true },
        { title: "Change Status", dataKey: "", align: "text-center", key: 12, checked: true, canChange: true },

    ]);

    useEffect(() => {
        getHistory();
    }, [currentPage, perPage]);

    useEffect(() => {
        if (search) {
            handleSearchCallback(search);
        }
    }, [currentPage, perPage]);

    useEffect(() => {
        getAllUsers();
    },[])

    
    // Conditionally include the last record based on isManager
    const displayedHeaders = isManager 
    ? headers // Show all headers if isManager is true
    : headers.slice(0, -1); // Remove the last item if isManager is false

    

    const getHistory = async () => {
        let result = await getCompletedTasks({currentPage,perPage});
        console.log('all-tasks', result)
        if (result.error) {
            swal({ text: result.error, icon: "warning", dangerMode: true });
        } else {
            setAllHistory(result.data.data['data']);
            setTotalItem(result.data.data['total']);
            setPerPage(result.data.data['per_page']);
            setLastPage(result.data.data['last_page']);
            setPerItem(result.data.data['data'].length);
        }
    }

    const sorting = (col) => {
        const sortResult = Sorting(allHistory, col, order);
        setOrder(sortResult[0]);
    }

    const handlePaginationCallback = (i, perPage) => {
        window.scrollTo(0, 0);
        if (perPage && perPage !== '') {
            setCurrentPage(1);
            setPerPage(perPage);
        } else { setCurrentPage(i); }
    }

    const handleSearchCallback = (input1, input2, input3, input4) => {
        let CreatedBy, AssignedTo, Priority, AppType;
        if (input1 === 'clear') {
            setSearch('');
        } else if (input1 === 'yes') {
            CreatedBy = createdBy;
            AssignedTo = assignedTo;
            Priority = priority;
            AppType = appType;
        } else {
            setSearch('yes');
            setCreatedBy(input1);
            setAssignedTo(input2)
            setPriority(input3);
            setAppType(input4);
            console.log(input1,input2,input3,input4)
            CreatedBy = input1;
            AssignedTo = input2;
            Priority = input3;
            AppType = input4;
        }
            const data = {
                createdBy: CreatedBy,
                assignedTo: AssignedTo,
                priority: Priority,
                appType: AppType,
            }
            console.log(data);
            let url = `search/completedTasks`;
            search_ApiManager(url, data, currentPage, perPage, (res) => {
                console.log('search result', res);
                setAllHistory(res.data.data['data']);
                setTotalItem(res.data.data['total']);
                setPerPage(res.data.data['per_page']);
                setLastPage(res.data.data['last_page']);
                setPerItem(res.data.data['data'].length);

            }, (err) => {
                console.log(err);
            });
        
    }
    const getAllUsers = async () => {
        let url = 'getAllUsers';
        view_ApiManager(url, 0, 0,(res) => {
            console.log('Get all Users',res);
            setAllUsers(res.data.data);
        },(err) => {
            console.log(err);
        });
     }
    const  handleStatusChange = async (taskId, status) => {
      
        const data = { status: status };
        let url = `updateTaskAttempt/${taskId}/${status}`;
        console.log(url)
        post_ApiManager(data, url, (res) => {
            if (res.data.status) {
                getHistory();
                swal("Success", "Task status updated successfully", "success");
            } else {
                swal("Error", "Failed to update task status", "error");
            }
        }, (err) => {
            console.log(err);
            swal("Error", "Failed to update task status", "error");
        });
    }

    return (
        <>
            <Title title={`User Processing Tasks Report`} back={true} />
            <div className="row w-100 pl-3 pr-3 mb-5 mt-3">
                <MultipleSearchs
                 input1={'Created By'}
                 input1_type={"dropdown"}
                 input1_multiple={"yes"}
                 input1Data={allUsers}
                 input1_filter_by={"name"}
                 input2={'Assigned To'}
                 input2_type={"dropdown"}
                 input2_multiple={"yes"}
                 input2Data={allUsers}
                 input2_filter_by={"name"}
                 input3={'Priority'}
                 input3_type={"dropdown"}
                 input3_multiple={"no"}
                 input3Data={priorities}
                 input3_filter_by={"title"}
                 input4={'App Type'}
                 input4_type={"dropdown"}
                 input4_multiple={"no"}
                 input4Data={category}
                 onSearchChange={handleSearchCallback}
                />
                <table className="table table-bordered bg-white mb-1">
                    {/* Headers */}
                    <thead className="thead-dark">
                        <tr>
                            {displayedHeaders.map(head => (
                                <th id={head.dataKey} className={head.align} onClick={() => sorting(head.dataKey)}
                                    key={head.key}>{head.title}</th>))}
                        </tr>
                    </thead>
                    {/* End Headers */}
                    <tbody>
                        {allHistory.map((value, index) => (
                            <tr key={index}>
                                <td className="text-center w-3">{++index}</td>
                                <td className="text-center">
                                <p style={{whiteSpace: 'pre-wrap'}}
                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value.task)}}></p></td>
                                <td className="text-center"> <a href={`/clients/view-client-detail/${value.clientid}`} rel="noopener noreferrer" className="text-blue-500 hover:underline">
                                    {value.clientname}</a></td>
                                <td className="text-center">{value.created_by_user}</td>
                                <td className="text-center">{value.assigned_to_user}</td>
                                <td className="text-center">{Number(value.note_type) === 1 ? 'Migration' : 'Education'}</td>
                                <td className="text-center"> <p className="text-gray-800 text-sm">
                                    {Number(value.note_type) === 1 ? (
                                        <a
                                            href={`/applications/view-migration-application-detail/${value.migration_id}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-500 hover:underline"
                                        >
                                            {value.migration_course_name || "-"}
                                        </a>
                                    ) : Number(value.note_type) === 2 ? (
                                        <a href={`/applications/view-education-application-detail/${value.education_id}`}
                                            target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline"
                                        >
                                            {value.education_course_name || "-"}
                                        </a>
                                    ) :  '-'}
                                </p></td>
                                <td className='text-center'>{value.priority.toUpperCase()}</td>
                                <td className="text-center">{value.status.toUpperCase()}</td>
                                <td className="text-center">{moment(value.due_date).format('D MMM YYYY')}</td>
                                <td className='text-center'>{value.complete_date ? moment(value.complete_date).format('D MMM YYYY') : '--'}</td>
                                <td className="text-center">
                                <select onChange={(e) => handleStatusChange(value.id, e.target.value)}
                                        className={`w-full p-2 text-sm border rounded bg-gray-50 focus:ring-2 focus:ring-blue-500 focus:ring-red-500' 
                                        }`}
                                    >
                                    <option value="">Select Status</option>
                                        <option value="pending">Pending</option>
                                        {/* <option value="in_progress">In Progress</option> */}
                                    </select>
                                </td>
                               
                            </tr>))}
                    </tbody>
                </table>
                <Paginations
                    currentPage={currentPage}
                    total={totalItem}
                    itemsPerPage={perPage}
                    lastPage={lastPage}
                    onPageChange={handlePaginationCallback}
                    totalItems={totalItem}
                    perItem={perItem}
                />
            </div>
        </>
    )
}
export default ProcessingReports;
