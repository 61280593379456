import ApiHandler from "../ApiManager/ApiHandler";

// Reusable function to handle API requests
const handleApiResponse = async (apiCall) => {
  try {
    const response = await apiCall;
    return response.success
      ? { data: response.data, error: null, message: response.data.message }
      : { data: null, error: response.message || 'An error occurred' };
  } catch (error) {
    return { data: null, error: error.message || 'An error occurred' };
  }
};

// POST Requests
export const createInternalNotes = async (userData) =>
  handleApiResponse(ApiHandler.post('/createInternalNotes', userData));

export const editInternalNotes = async (userData) =>
  handleApiResponse(ApiHandler.post('/editInternalNotes', userData));

export const internalTaskCompletion = async (userData) =>
  handleApiResponse(ApiHandler.post('/internalTaskCompletion', userData));

// GET Requests with Parameters
export const getInternalNotes = async ({ currentPage = 1, perPage = 10 } = {}) =>
  handleApiResponse(ApiHandler.get(`/getInternalNotes?page=${currentPage}&perPage=${perPage}`));

export const searchInternalNotes = async ({ currentPage = 1, perPage = 10 } = {}, userData) =>
  handleApiResponse(ApiHandler.post(`/searchInternalNotes?page=${currentPage}&perPage=${perPage}`, userData));

export const internalNoteDetail = async (noteid) =>
  handleApiResponse(ApiHandler.get(`/getInternalNoteDetail/${noteid}`));

export const internalNoteByUser = async (id) =>
  handleApiResponse(ApiHandler.get(`/getInternalNotesByUser/${id}`));

export const getUnSeenAlert = async (id) =>
  handleApiResponse(ApiHandler.get(`/get-un-seen-alert/${id}`));

// Simple GET Requests
export const getInboxUnSeen = async () =>
  handleApiResponse(ApiHandler.get('/get-inbox-unseen'));

export const getMarkAsSeen = async () =>
  handleApiResponse(ApiHandler.get('/mark-as-seen'));

export const getChatNotifications = async () =>
  handleApiResponse(ApiHandler.get('/getChatNotification'));

export const getUnSeen = async () =>
  handleApiResponse(ApiHandler.get('/get-un-seen'));

export const makeHrAdmin = async (id) =>
  handleApiResponse(ApiHandler.get(`/makeUserHrAdmin/${id}`));

export const removeHrAdmin = async (id) =>
  handleApiResponse(ApiHandler.get(`/removeUserHrAdmin/${id}`));

export const deleteInternalNote = async (id) =>
  handleApiResponse(ApiHandler.get(`/deleteInternalNote/${id}`));

export const getDeactivatedUsers = async () =>
  handleApiResponse(ApiHandler.get(`/getDeactivatedUsers`));

export const getrecentActivity = async () =>
  handleApiResponse(ApiHandler.get(`/getRecentActivity`));

export const addIntake = async (userData) =>
  handleApiResponse(ApiHandler.post(`/intakes`, userData));

export const updateIntake = async (id, userData) =>
  handleApiResponse(ApiHandler.put(`/intakes/${id}`, userData));

export const getAllIntakes = async ({ currentPage = 1, perPage = 10 } = {}) =>
  handleApiResponse(ApiHandler.get(`/allIntakes?page=${currentPage}&perPage=${perPage}`));

export const getIntakes = async () =>
  handleApiResponse(ApiHandler.get('/getIntakes'));

export const levelsByCategory = async () =>
  handleApiResponse(ApiHandler.get('/levelsByCategory'));

export const searchIntakes = async ({ currentPage = 1, perPage = 10 } = {}, data) =>
  handleApiResponse(ApiHandler.post(`/search/intakes/?page=${currentPage}&perPage=${perPage}`, data));

export const sendEmailToClient = async ({data}) =>
  handleApiResponse(ApiHandler.post(`/sendEmailToClient`, data));

export const logoutUser = async () => {
  handleApiResponse(ApiHandler.get(`/logout`));
};

export const getAllTasks = async ({ currentPage = 1, highCurrentPage = 1, perPage = 10, highPerPage = 10 } = {}) => 
  handleApiResponse(ApiHandler.get(`/getAllTasks?page_standard=${currentPage}&page_high=${highCurrentPage}&perPage=${perPage}&highPerPage=${highPerPage}`
  ));

export const getCompletedTasks = async ({ currentPage = 1, perPage = 10 } = {}) => 
  handleApiResponse(ApiHandler.get(`/getCompletedTasks?page=${currentPage}&perPage=${perPage}`
));

export const getApplication = async (type) => {
  handleApiResponse(ApiHandler.get(`/getApplicationsByType/${type}`));
};
export const getUnclaimTask = async (task) => {
  handleApiResponse(ApiHandler.get(`/getUnclaimTask/${task}`));
};



